.movie-container {
    position: relative;
  }
  
  .movie-bg, .movie-overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  
  .movie-bg {
    width: 100%;
    height: 515px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .movie-overlay {
    background: rgba(0, 0, 0, 0.8);
    height: 515px;
    z-index: 2;
  }
  
  .movie-details {
    position: relative;
    z-index: 3;
    display: grid;
    grid-template-areas: "movieImage movieBody";
    grid-template-columns: 400px 1fr;
    grid-template-rows: 1fr;
    grid-gap: 10px;
    height: 100vh;
    padding-top: 200px;
    padding-right: 50px;
    padding-left: 50px;
  }
  
  .movie-image {
    grid-area: movieImage;
    width: 330px;
    height: 550px;
  
    img {
      display: inline-block;
      max-height: 100%;
      max-width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  
  .movie-body {
    grid-area: movieBody;
  
    .movie-overview {
        height: auto;
  
        .title {
          margin-top: -10px;
          font-size: 36px;
          font-weight: 700;
          color: #ffffff;
          text-align: left;
          text-transform: uppercase;
          margin-bottom: 25px;
  
          span {
            font-size: 24px;
            font-weight: 300;
            color: #4f5b68;
          }
        }
  
        .movie-genres {
          .genres {
              height: 49px;
              line-height: 49px;
              display: flex;
              margin-bottom: 25px;
  
              li {
                list-style: none;
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
                color: #dd003f;
                padding-right: 35px;
                margin-left: -20px;
              }
          }
        }
  
        .rating {
            height: 49px;
            line-height: 49px;
            display: flex;
            font-size: 25px;
            margin-bottom: 90px;
  
            .fas {
              color: #f5b50a;
              padding-left: 4px;
            }
  
            span {
              color: #9aa9bb;
              margin-top: -10px;
            }
  
            p {
              color: #dd003f;
              margin-left: 10px;
              font-size: 18px;
            }
        }
    }
  
  }
  
  @media (max-width: 1100px) {
    .movie-details {
      grid-template-areas: "movieImage" "movieBody";
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }
  }
  
  @media (max-width: 600px) {
    .movie-details {
      grid-template-areas: "movieImage" "movieBody";
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }
  
    .movie-body {
      .movie-overview {
        .title {
          width: 348px;
          font-size: 16px;
          overflow-wrap: break-word;
          word-wrap: break-word;
          hyphens: auto;
          span {
            display: flex !important;
          }
        }
  
        .movie-genres {
          text-align: left;
          margin-bottom: 50px;
  
          .genres {
            display: inline-block;
  
            li {
              list-style: none;
              font-size: 14px;
              font-weight: 700;
              text-transform: uppercase;
              color: #dd003f;
            }
          }
        }
      }
  
      .rating {
        display: block !important;
  
        span {
          color: #9aa9bb;
          padding-top: 10px;
          float: left !important;
          margin-left: 3px !important;
        }
  
        p {
          color: #dd003f;
          float: left !important;
          padding-top: 10px;
        }
      }
    }
  }
  
  @media (max-width: 375px) {
    .movie-image {
      width: 230px;
      height: 450px;
    }
  
    .movie-body {
      .movie-overview {
        .title {
          font-size: 16px;
          width: auto;
          span {
            display: flex;
            font-size: 18px;
          }
        }
      }
    }
  
    .movie-image {
        margin: 0 auto;
    }
  }
  
  @media (max-width: 320px) {
    .movie-body {
      .movie-overview {
        .title {
          font-size: 14px;
          width: 90%;
          span {
            display: flex;
            font-size: 18px;
          }
        }
      }
  
      .rating {
          width: 90%;
          .rating-stars {
            font-size: 18px !important;
          }
  
          span {
            font-size: 80%;
          }
      }
    }
  
    .movie-image {
      margin: 0 auto;
    }
  }