.spinner {
    margin: 100px auto 0;
    width: 100px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .spinner > div {
    width: 18px;
    height: 18px;
    background-color: #ffffff;
    border-radius: 100%;
    display: inline-block;
    animation: bounce 1.4s infinite ease-in-out both;
    -webkit-animation: bounce 1.4s infinite ease-in-out both;
  }
  
  .spinner .bounce1 {
    animation-delay: -0.32s;
    -webkit-animation-delay: -0.32s;
  }
  
  .spinner .bounce2 {
    animation-delay: -0.16s;
    -webkit-animation-delay: -0.16s;
  }
  
  @keyframes bounce {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  
  @-webkit-keyframes bounce {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }